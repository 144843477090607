<template>
  <div :class="{ error: hasErrors }">
    <slot />
    <div class="errors" v-if="hasErrors">
      <p :class="['error', ...errorClasses]">
        {{ activeErrorMessagesModified[0] }}
      </p>
    </div>
  </div>
</template>

<script>
import { singleErrorExtractorMixin } from 'vuelidate-error-extractor';

export default {
  mixins: [singleErrorExtractorMixin],
  name: 'vuelidate-error-extractor',
  methods: {
    IsPropInvalid(name) {
      return (
        Object.hasOwnProperty.call(this.validator, name) &&
        !this.validator[name]
      );
    },
  },
  computed: {
    activeErrorMessagesModified() {
      let toDateTime = this.$options.filters.toDateTime;
      let toShortDate = this.$options.filters.toShortDate;
      let isTime = this.settings.isTime;
      return this.activeErrorMessages.map(m => {
        let dateFormatter = toShortDate;
        if (isTime) {
          dateFormatter = toDateTime;
        }
        //prettier-ignore
        if (m == 'Érvénytelen dátum' && this.settings.minDate) {
          if (this.IsPropInvalid('minDate')) {
            return `Adjon meg ${dateFormatter(this.settings.minDate)}-nál nagyobb dátumot`;
          }
          if (this.IsPropInvalid('maxDate')) {
            return `Adjon meg ${dateFormatter(this.settings.maxDate)}-nál kisebb dátumot`;
          }
        }
        return m;
      });
    },
  },
  props: {
    errorClasses: {
      type: Array,
      default: function() {
        return [];
      },
    },
    settings: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
};
</script>

<style lang="scss">
.errors .error {
  color: #dc3545;
  margin-bottom: 0;
}
.error input,
.error .select2-selection.select2-selection--single,
.error .note-editor.note-frame.card,
.error .multiselect__tags {
  border-color: #dc3545;
}
</style>
